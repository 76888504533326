import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Button, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import PATHS from 'paths'
import { FUNERAL_PLAN_TELEPHONE_NUMBER } from 'config'
import { PRODUCTS } from 'lib/products/constants'
import FUNERAL_PLANS_JSONLD from 'lib/json-ld/funeral-plans'
import {
  funeralPlanBreadCrumb,
  getStructuredBreadCrumbs,
} from 'lib/json-ld/getStructuredBreadCrumbs'
import DefaultLayout from 'layouts/DefaultLayout'
import Explainer from 'views/cremation/components/Explainer'
import JSONLD from 'components/JSONLD'
import { FORMATTED_CREMATION_FUNERAL_PLAN_FEE } from 'views/cremation/shared/constants'
import CallUsTodayCta from 'components/CallUsTodayCta'

import { COMPARE_PROVIDERS, GUIDE_DATA, HERE_TO_HELP_DATA } from './data'
import CompareProviders from './components/CompareProviders'

const pageTitle = `Funeral Plans for ${FORMATTED_CREMATION_FUNERAL_PLAN_FEE} - Arrange today with Farewill`
const pageDescription = `Arrange a funeral plan for ${FORMATTED_CREMATION_FUNERAL_PLAN_FEE}. This is a prepaid funeral for those looking to plan ahead by arranging a meaningful funeral in advance.`

const StyledButton = styled(Button.Primary)`
  ${screenMin.m`
    max-width: 210px;
  `}
`

const Compare = (): React.ReactElement => (
  <>
    <JSONLD
      data={[
        FUNERAL_PLANS_JSONLD,
        getStructuredBreadCrumbs({ itemListElements: [funeralPlanBreadCrumb] }),
      ]}
    />
    <DefaultLayout
      title={pageTitle}
      description={pageDescription}
      product={PRODUCTS.FUNERAL_PLAN}
      headerColor={COLOR.ACCENT.PRIMARY_60}
      footerShowFuneralPlansLegalInformation
      telephoneNumber={FUNERAL_PLAN_TELEPHONE_NUMBER}
      usePhoneNumberTracking={false}
    >
      <CompareProviders
        title={COMPARE_PROVIDERS.title}
        description={COMPARE_PROVIDERS.description}
      />
      <Wrapper background={COLOR.WHITE}>
        <Wrapper
          as={Explainer}
          paddingFromL={['XL', 'XL', 'L']}
          title={HERE_TO_HELP_DATA.title}
          description={HERE_TO_HELP_DATA.description}
          descriptionFontSize="L"
          imagePath={HERE_TO_HELP_DATA.imagePath}
          additionalContent={<CallUsTodayCta product={PRODUCTS.FUNERAL} />}
          desktopImagePosition="right"
        />
        <Wrapper
          paddingFromL={[0, 'XL', 'XL']}
          as={Explainer}
          title={GUIDE_DATA.title}
          description={GUIDE_DATA.description}
          descriptionFontSize="L"
          imagePath={GUIDE_DATA.imagePath}
          mobileImagePosition="bottom"
          additionalContent={
            <StyledButton
              stretch
              tag={Link}
              to={PATHS.CREMATION.FUNERAL_PLAN_GET_A_GUIDE}
            >
              Get your free guide
            </StyledButton>
          }
        />
      </Wrapper>
    </DefaultLayout>
  </>
)

export default Compare
