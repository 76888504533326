import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Button, Divider, Grid, H, Image, P, Wrapper } from '@farewill/ui'
import { BREAKPOINT, COLOR, GTR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

import PATHS from 'paths'
import BreakpointSwitch from 'components/BreakpointSwitch'
import { FUNERAL_PLANS_COMPETITOR_PRICES_LAST_UPDATED } from 'lib/funeralPlans/constants'
import { LABELS, PROVIDERS } from './data'
import SingleProvider from './SingleProvider'

type CompareProvidersProps = {
  title: string
  description: string
}

const StyledCompareProviders = styled(Wrapper)`
  position: relative;
`

const StyledContent = styled(Wrapper)`
  position: relative;
  z-index: 2;
`

const StyledBackground = styled(Wrapper)`
  position: absolute;
  bottom: 0;
  left: 0;
  right; 0;
  height: calc(40% + 1px);
  width: 100%;
  background: ${COLOR.ACCENT.PRIMARY};

  ${screenMax.m`
    height: 60%;
  `}
`

const StyledDescriptions = styled(Grid)`
  border-radius: ${GTR.S};
  margin-top: 130px;
  row-gap: ${GTR.S};
  margin-right: -960px;

  @media screen and (max-width: 1300px) {
    margin-right: -74vw;
  }
`

const StyledDescriptionWrapper = styled(Grid.Item)`
  ${screenMax.l`
    display: none;
  `}
`

const StyledTexture = styled(Wrapper)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40%;
  width: 100%;
  transform: rotate(180deg);

  img {
    width: 100%;
  }

  ${screenMax.m`
    bottom: 60%;
  `}
`

const Texture = () => (
  <StyledTexture>
    <Image path="textures/edge-2" width={1280} />
  </StyledTexture>
)

const CompareProviders = ({ title, description }: CompareProvidersProps) => (
  <StyledCompareProviders
    background={COLOR.ACCENT.PRIMARY_60}
    padding={[0, 0]}
    paddingFromL={['XL', 0, 0]}
  >
    <StyledBackground />
    <Texture />
    <StyledContent>
      <Wrapper
        container
        centeredFromM
        maxWidthInColumns={9}
        containerPaddingBottom={0}
      >
        <H tag="h1" size="L" decorative>
          {title}
        </H>
        <P size="L" color={COLOR.BLACK}>
          {description}
        </P>
      </Wrapper>

      <Grid container>
        <StyledDescriptionWrapper span={3} noTrim>
          <StyledDescriptions background={COLOR.WHITE} padding={['M', 0]}>
            {Object.keys(LABELS).map((label, i) => (
              <>
                <Grid.Item padding={[0, 'M']}>
                  <P color={COLOR.BLACK} strong>
                    {LABELS[label as keyof typeof LABELS]}
                  </P>
                </Grid.Item>
                {Object.keys(LABELS).length > i + 1 && (
                  <Grid.Item>
                    <Divider />
                  </Grid.Item>
                )}
              </>
            ))}
          </StyledDescriptions>
        </StyledDescriptionWrapper>
        <Grid.Item span={12} spanFromXL={9}>
          <Grid>
            {PROVIDERS.map((provider, i) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <SingleProvider {...provider} key={provider.name} index={i} />
            ))}
          </Grid>
        </Grid.Item>
      </Grid>
      <Wrapper
        container
        centeredFromL
        maxWidthInColumns={11}
        containerPaddingTop={0}
      >
        <P color={COLOR.BLACK}>
          This information is based on similar plans from each provider. It’s
          correct on the date of publishing,{' '}
          {FUNERAL_PLANS_COMPETITOR_PRICES_LAST_UPDATED}. The same features
          apply with Farewill Funerals whether you choose to make a single
          payment, or pay in monthly instalments. Farewill Funeral plans are
          available in England and Wales.
        </P>
        <H tag="h2" size="M" padding={['M', 0, 0]} paddingFromM={['XL', 0, 0]}>
          It’s easy to set up your funeral plan
        </H>

        <BreakpointSwitch
          breakAt={BREAKPOINT.S}
          aboveComponent={
            <Button.White
              size="L"
              tag={Link}
              to={PATHS.CREMATION.FUNERAL_PLANS}
            >
              Find out more
            </Button.White>
          }
          belowComponent={
            <Button.White
              size="L"
              tag={Link}
              to={PATHS.CREMATION.FUNERAL_PLANS}
              stretch
            >
              Find out more
            </Button.White>
          }
        />
      </Wrapper>
    </StyledContent>
  </StyledCompareProviders>
)

export default CompareProviders
