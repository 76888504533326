import React from 'react'
import styled from 'styled-components'
import { Button, ChevronDownIcon } from '@farewill/ui'

import PreventOrphan from 'components/PreventOrphan'

type DropdownPromptProps = {
  ariaId: string
  className?: string
  title: string
  isOpen: boolean
  triggerDropdown: (isOpen: boolean) => void
}

const StyledChevronIcon = styled(ChevronDownIcon)`
  transition: transform 0.3s ease-in-out;

  ${({ $isOpen }) =>
    $isOpen &&
    `
    transform: rotate(-180deg);
  `}
`

const StyledButton = styled(Button.Plain)`
  &:not([disabled]):not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }
`

const DropdownPrompt = ({
  ariaId,
  className,
  title,
  isOpen,
  triggerDropdown,
}: DropdownPromptProps): React.ReactElement => (
  <StyledButton
    className={className}
    onClick={() => triggerDropdown(isOpen)}
    icon={<StyledChevronIcon $isOpen={isOpen} size="S" />}
    aria-controls={ariaId}
    aria-expanded={isOpen}
    stretch
    separateIcon
  >
    <PreventOrphan>{title}</PreventOrphan>
  </StyledButton>
)

export default DropdownPrompt
