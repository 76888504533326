import { ROOT_URL } from 'config'

interface ItemListElement {
  '@type': string
  position: number
  name: string
  item: string
}
interface BreadCrumbs {
  '@context': string
  '@type': string
  itemListElement: ItemListElement[]
}

export const funeralPlanBreadCrumb = {
  '@type': 'ListItem',
  position: 3,
  name: 'Funeral Plans',
  item: `${ROOT_URL}/funerals/funeral-plans/`,
}

export const funeralDirectBreadCrumb = {
  '@type': 'ListItem',
  position: 3,
  name: 'Direct Cremation',
  item: `${ROOT_URL}/funerals/direct-cremation/`,
}

export const getStructuredBreadCrumbs = ({
  itemListElements = [],
}: {
  itemListElements?: ItemListElement[]
}): BreadCrumbs => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Home',
      item: 'https://farewill.com/',
    },
    {
      '@type': 'ListItem',
      position: 2,
      name: 'Funerals',
      item: 'https://farewill.com/funerals/',
    },
    ...itemListElements,
  ],
})
