import React from 'react'
import styled from 'styled-components'

import { TRANSITION_DURATION_IN_MS } from './constants'

type ContentWrapperProps = {
  isOpen: boolean
  isTransitioning: boolean
  contentHeight: number | null
  children: React.ReactNode
  contentEl: any
  ariaId: string
  className?: string
  tag?: React.ElementType
}

const StyledContentWrapper = styled.div<{
  $isOpen: boolean
  $isTransitioning: boolean
  $contentHeight: number | null
}>`
  will-change: opacity, max-height;
  transition: opacity ${TRANSITION_DURATION_IN_MS}ms ease-in-out,
    max-height ${TRANSITION_DURATION_IN_MS}ms ease-in-out;
  overflow: hidden;

  ${({ $isOpen, $isTransitioning }) =>
    !$isOpen &&
    !$isTransitioning &&
    `
      position: absolute;
      opacity: 0;
      overflow: hidden;
      visibility: hidden;
  `}

  ${({ $isTransitioning }) =>
    $isTransitioning &&
    `
      opacity: 0;
      max-height: 0;
  `}

  ${({ $isOpen, $contentHeight }) =>
    $isOpen &&
    `
      opacity: 1;
      max-height: ${$contentHeight}px;
  `}
`

const ContentWrapper = ({
  ariaId,
  isOpen,
  isTransitioning,
  contentHeight,
  children,
  contentEl,
  className,
  tag,
}: ContentWrapperProps): React.ReactElement => (
  <StyledContentWrapper
    as={tag}
    ref={contentEl}
    className={className}
    $isOpen={isOpen}
    $isTransitioning={isTransitioning}
    $contentHeight={contentHeight}
    id={ariaId}
    aria-hidden={!isOpen}
  >
    {children}
  </StyledContentWrapper>
)

export default ContentWrapper
