import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Wrapper, Button, H, P } from '@farewill/ui'
import { COLOR, GTR, BORDER_RADIUS } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { Link } from 'gatsby'

import { LIVE_PRODUCTS, PRODUCTS_MAP } from 'lib/products/constants'
import { PRODUCT_SUPPORT_MAP } from 'lib/time/constants'
import { formatHour } from 'lib/time/formatting'
import useProductSupportStatus from 'lib/time/useProductSupportStatus'
import TelephoneNumber from 'components/TelephoneNumber'
import PhoneLineOpenIndicator from 'components/PhoneLineOpenIndicator'

import ProductContactSpecialists from './ProductContactSpecialists'

const OpenTimesWrapper = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  padding: 7px 14px;
  border-radius: ${BORDER_RADIUS.M};
  display: inline-block;
  width: auto;
`

const HorizontalWrapper = styled(Wrapper)`
  display: inline-flex;
  align-items: center;
  ${({ $stretch }) => `${$stretch && 'width: 100%;'}`}
`

const OpenMessageWrapper = styled(Wrapper)`
  display: inline-block;
  width: auto;

  ${screenMin.m`
    min-width: 108px;
  `}
`

const StyledCta = styled(H)`
  display: inline;
  margin-right: ${GTR.XS};
`

const StyledCallbackLink = styled(P)`
  display: inline;
`

const StyledTelephoneNumber = styled(TelephoneNumber)`
  width: 100%;
`

const ProductContact = ({
  cta,
  ctaMessage,
  hideCta,
  selectedProduct,
  specialistsOutlineColor,
  showSpecialists,
  showOpenTimes,
  showLineOpenMessage,
  showCallbackLinkWhenOpen,
  highlightTelephoneNumber,
  highlightTelephoneNumberColor,
  telephoneNumberSize,
  customPhoneNumber,
  preventSwapping,
  stretchButton,
  className,
  hasButtonStyling,
  buttonPrefix,
}) => {
  const isProductSupportOpen = useProductSupportStatus(selectedProduct)

  const {
    canRequestCallback,
    callbackLink,
    telephoneNumber: productTelephoneNumber,
  } = PRODUCTS_MAP[selectedProduct]

  const supportInfo = PRODUCT_SUPPORT_MAP[selectedProduct]
  const supportOpenTimes = supportInfo.overflow
    ? '24/7'
    : `until ${formatHour(supportInfo.endTime)}`

  const specialistsSection = showSpecialists && (
    <ProductContactSpecialists
      selectedProduct={selectedProduct}
      outlineColor={specialistsOutlineColor}
      hideOpenIndicator={showOpenTimes}
    />
  )

  if (!isProductSupportOpen && canRequestCallback) {
    return (
      <Wrapper margin={['S', 0]}>
        <Button.Secondary tag={Link} to={callbackLink} stretch={stretchButton}>
          Request a callback
        </Button.Secondary>
        {specialistsSection}
      </Wrapper>
    )
  }

  return (
    <Wrapper className={className} margin={['S', 0]}>
      {ctaMessage && (
        <P size="M" maxWidth={365}>
          {ctaMessage}
        </P>
      )}
      {!hideCta && (
        <Wrapper margin={['S', 0]}>
          <StyledCta size="S" tag="h3">
            {cta}
          </StyledCta>
          {canRequestCallback && showCallbackLinkWhenOpen && (
            <StyledCallbackLink size="M">
              or <Link to={callbackLink}> request a callback</Link>
            </StyledCallbackLink>
          )}
        </Wrapper>
      )}

      <HorizontalWrapper $stretch={stretchButton}>
        <StyledTelephoneNumber
          telephoneNumber={customPhoneNumber ?? productTelephoneNumber}
          size={telephoneNumberSize}
          highlighted={highlightTelephoneNumber}
          highlightColor={highlightTelephoneNumberColor}
          preventSwapping={preventSwapping}
          hasButtonStyling={hasButtonStyling}
          buttonPrefix={buttonPrefix}
        />
        {isProductSupportOpen && showLineOpenMessage && (
          <Wrapper margin={[0, 0, 0, 'M']}>
            <OpenMessageWrapper>
              <PhoneLineOpenIndicator text="Line open" />
            </OpenMessageWrapper>
          </Wrapper>
        )}
      </HorizontalWrapper>

      {specialistsSection}

      {isProductSupportOpen && showOpenTimes && (
        <Wrapper margin={['XS', 0]}>
          <OpenTimesWrapper>
            <PhoneLineOpenIndicator text={`We’re open ${supportOpenTimes}`} />
          </OpenTimesWrapper>
        </Wrapper>
      )}

      {hideCta && canRequestCallback && showCallbackLinkWhenOpen && (
        <P size="M" margin={['XS', 0, 0]}>
          Or <Link to={callbackLink}> request a callback</Link>
        </P>
      )}
    </Wrapper>
  )
}

ProductContact.propTypes = {
  cta: PropTypes.string,
  ctaMessage: PropTypes.string,
  hideCta: PropTypes.bool,
  selectedProduct: PropTypes.oneOf(LIVE_PRODUCTS).isRequired,
  specialistsOutlineColor: PropTypes.string,
  showSpecialists: PropTypes.bool,
  showOpenTimes: PropTypes.bool,
  showLineOpenMessage: PropTypes.bool,
  showCallbackLinkWhenOpen: PropTypes.bool,
  highlightTelephoneNumber: PropTypes.bool,
  highlightTelephoneNumberColor: PropTypes.string,
  stretchButton: PropTypes.bool,
  className: PropTypes.string,
  telephoneNumberSize: PropTypes.oneOf(['small', 'medium', 'large']),
  customPhoneNumber: PropTypes.string,
  preventSwapping: PropTypes.bool,
  hasButtonStyling: PropTypes.bool,
  buttonPrefix: PropTypes.string,
}

ProductContact.defaultProps = {
  cta: 'Call us now',
  ctaMessage: '',
  hideCta: false,
  specialistsOutlineColor: undefined,
  showSpecialists: false,
  showOpenTimes: false,
  showLineOpenMessage: false,
  showCallbackLinkWhenOpen: false,
  highlightTelephoneNumber: false,
  highlightTelephoneNumberColor: COLOR.ACCENT.PRIMARY,
  stretchButton: false,
  className: null,
  telephoneNumberSize: 'large',
  customPhoneNumber: null,
  preventSwapping: false,
  hasButtonStyling: false,
  buttonPrefix: null,
}

export default ProductContact
