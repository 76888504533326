import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Wrapper, Divider } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import { LIVE_PRODUCTS } from 'lib/products/constants'
import useProductSupportStatus from 'lib/time/useProductSupportStatus'
import specialistsExist from 'lib/specialists/specialistsExist'
import Specialists from 'components/Specialists'
import PhoneLineOpenIndicator from 'components/PhoneLineOpenIndicator'

const SpecialistsWrapper = styled(Wrapper)`
  display: inline-flex;
  align-items: center;
`

const SpecialistsDivider = styled(Divider)`
  height: ${GTR.L};
  margin: 0 ${GTR.S};
`

const ProductContactSpecialists = ({
  selectedProduct,
  outlineColor,
  hideOpenIndicator,
  numberOfExperts,
}) => {
  const isProductSupportOpen = useProductSupportStatus(selectedProduct)

  if (!specialistsExist(selectedProduct)) return null

  return (
    <Wrapper margin={['M', 0]}>
      <SpecialistsWrapper>
        <Specialists
          group={selectedProduct}
          extraSmall
          max={numberOfExperts}
          outlineColor={outlineColor}
        />
        {isProductSupportOpen && !hideOpenIndicator && (
          <>
            <SpecialistsDivider vertical />
            <PhoneLineOpenIndicator short />
          </>
        )}
      </SpecialistsWrapper>
    </Wrapper>
  )
}

ProductContactSpecialists.propTypes = {
  selectedProduct: PropTypes.oneOf(LIVE_PRODUCTS).isRequired,
  outlineColor: PropTypes.string,
  numberOfExperts: PropTypes.number,
  hideOpenIndicator: PropTypes.bool,
}

ProductContactSpecialists.defaultProps = {
  outlineColor: undefined,
  hideOpenIndicator: false,
  numberOfExperts: 3,
}

export default ProductContactSpecialists
