import { ExplainerProps } from 'views/cremation/components/Explainer'

export const HERE_TO_HELP_DATA: ExplainerProps = {
  title: 'Our friendly team is here to help',
  description:
    'Call us today. We’ll explain the details of the plan and answer any questions you may have. When you’re ready, we’ll help you set up your plan.',
  imagePath: 'e_trim/illustrations/funeral-coordinator-circle-background',
}

export const GUIDE_DATA: ExplainerProps = {
  title: 'Get your free funeral plan guide',
  description:
    'Our guide covers everything you need to know about our prepaid direct cremation funeral plans.',
  imagePath: 'e_trim/funeral-plans-assets/guide-with-circle-bg',
}

export const COMPARE_PROVIDERS = {
  title: 'How our funeral plans compare',
  description:
    'To make sure we’re giving you great value for money, we’ve compared our prices with some of the leading prepaid funeral plans.',
}
