import {
  ROOT_URL,
  TRUSTPILOT_RATING_OUT_OF_5,
  TRUSTPILOT_REVIEWS_TOTAL,
  CREMATION_SIMPLE_FEE,
  CREMATION_FUNERAL_PLAN_FEE,
} from 'config'
import { formatPriceInPounds } from 'lib/formatting/pricing'

export default {
  '@context': 'https://schema.org',
  '@type': 'Product',
  name: 'Funeral Plans',
  image: `${ROOT_URL}/logos/share.jpg`,
  description: `We'll help you arrange a funeral plan from ${formatPriceInPounds(
    CREMATION_SIMPLE_FEE
  )}. This is a prepaid funeral for those looking to arrange a meaningful funeral in advance.`,
  brand: {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: ROOT_URL,
    name: 'Farewill',
    logo: {
      '@type': 'ImageObject',
      url: `${ROOT_URL}/icons/circle-lockup-logo-icon-500.png`,
    },
    sameAs: 'https://en.wikipedia.org/wiki/Farewill',
  },
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: TRUSTPILOT_RATING_OUT_OF_5,
    reviewCount: TRUSTPILOT_REVIEWS_TOTAL,
    bestRating: 5,
    worstRating: 1,
  },
  offers: {
    '@type': 'Offer',
    url: `${ROOT_URL}/funerals/funeral-plans/`,
    priceCurrency: 'GBP',
    price: CREMATION_FUNERAL_PLAN_FEE,
    priceValidUntil: '2030-11-20',
    availability: 'https://schema.org/InStock',
  },
}
