import React from 'react'
import styled from 'styled-components'

import {
  ContentWrapper,
  DropdownPrompt,
  TriggerDropdown,
  useDropdown,
} from 'lib/hooks/useDropdown'

export type DropdownProps = {
  title: string
  openTitle?: string
  titlePosition?: 'top' | 'bottom'
  defaultOpen?: boolean
  children: React.ReactNode
  ariaId?: string
}

/**
 * Exporting this allows us to pass contextual override styles to DropdownPrompt
 * from where Dropdown is used, using component referencing[1].
 * [1] https://styled-components.com/docs/advanced#referring-to-other-components
 */
export const StyledDropdownPrompt = styled(DropdownPrompt)`
  padding: 0;
`

const Prompt = ({
  ariaId,
  title,
  isOpen,
  openTitle,
  triggerDropdown,
}: {
  ariaId: DropdownProps['ariaId']
  title: DropdownProps['title']
  isOpen: boolean
  openTitle: DropdownProps['openTitle']
  triggerDropdown: TriggerDropdown
}) => (
  <StyledDropdownPrompt
    ariaId={ariaId || title}
    title={isOpen && openTitle ? openTitle : title}
    isOpen={isOpen}
    triggerDropdown={triggerDropdown}
  />
)

const Dropdown = ({
  ariaId,
  title,
  openTitle,
  titlePosition = 'top',
  defaultOpen = false,
  children,
}: DropdownProps): React.ReactElement => {
  const { contentEl, contentHeight, isOpen, isTransitioning, triggerDropdown } =
    useDropdown(defaultOpen)

  return (
    <div>
      {titlePosition === 'top' && (
        <Prompt
          ariaId={ariaId}
          isOpen={isOpen}
          openTitle={openTitle}
          title={title}
          triggerDropdown={triggerDropdown}
        />
      )}

      <ContentWrapper
        ariaId={ariaId || title}
        contentEl={contentEl}
        isOpen={isOpen}
        isTransitioning={isTransitioning}
        contentHeight={contentHeight}
      >
        {children}
      </ContentWrapper>

      {titlePosition === 'bottom' && (
        <Prompt
          ariaId={ariaId}
          isOpen={isOpen}
          openTitle={openTitle}
          title={title}
          triggerDropdown={triggerDropdown}
        />
      )}
    </div>
  )
}

export default Dropdown
