import React from 'react'
import { Wrapper, H, P } from '@farewill/ui'
import { BREAKPOINT } from '@farewill/ui/tokens'

import { PRODUCTS } from 'lib/products/constants'
import useProductSupportStatus from 'lib/time/useProductSupportStatus'
import ProductContact from 'components/ProductContact'
import BreakpointSwitch from 'components/BreakpointSwitch'

export interface CallUsTodayProps {
  telephoneNumberSize?: 'small' | 'medium' | 'large'
  subtitle?: string
  headerSize?: 'XS' | 'S' | 'M'
  product: PRODUCTS
  preventSwapping?: boolean
  customPhoneNumber?: string
}

const CallUsTodayCta = ({
  telephoneNumberSize = 'small',
  subtitle,
  headerSize = 'XS',
  product,
  preventSwapping,
  customPhoneNumber,
}: CallUsTodayProps): React.ReactElement => {
  const isProductSupportOpen = useProductSupportStatus(product)
  return (
    <Wrapper margin={['L', 0, 0]}>
      <H size={headerSize} tag="h4">
        {isProductSupportOpen ? 'Call us today' : 'We’re here to help'}
      </H>

      {subtitle && <P>{subtitle}</P>}
      <BreakpointSwitch
        breakAt={BREAKPOINT.S}
        aboveComponent={
          <ProductContact
            telephoneNumberSize={telephoneNumberSize}
            selectedProduct={product}
            preventSwapping={preventSwapping}
            customPhoneNumber={customPhoneNumber}
            hideCta
            highlightTelephoneNumber
            showSpecialists
          />
        }
        belowComponent={
          <ProductContact
            telephoneNumberSize={telephoneNumberSize}
            selectedProduct={product}
            preventSwapping={preventSwapping}
            customPhoneNumber={customPhoneNumber}
            hideCta
            highlightTelephoneNumber
            showSpecialists
            stretchButton
          />
        }
      />
    </Wrapper>
  )
}

export default CallUsTodayCta
