import { createRef, useState, useEffect, RefObject } from 'react'

import { TRANSITION_DURATION_IN_MS } from './constants'

export type TriggerDropdown = (currentlyOpen: boolean) => true | null

export type UseDropdownValue = {
  contentEl: RefObject<HTMLDivElement>
  contentHeight: number | null
  isOpen: boolean
  isTransitioning: boolean
  triggerDropdown: TriggerDropdown
}

export const useDropdown = (defaultState = false): UseDropdownValue => {
  const [isOpen, setIsOpen] = useState(defaultState)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [contentHeight, setContentHeight] = useState<number | null>(null)
  const contentEl = createRef<HTMLDivElement>()

  const triggerDropdown: TriggerDropdown = (currentlyOpen) => {
    if (currentlyOpen) {
      setIsOpen(false)
      setTimeout(() => setIsTransitioning(false), TRANSITION_DURATION_IN_MS)
      return null
    }

    const ref = contentEl.current
    setContentHeight(ref?.clientHeight || null)
    setIsTransitioning(true)
    return true
  }

  useEffect(() => {
    if (isTransitioning && !isOpen) setTimeout(() => setIsOpen(true), 50)
  }, [isTransitioning])

  return {
    contentEl,
    contentHeight,
    isOpen,
    isTransitioning,
    triggerDropdown,
  }
}

export { default as ContentWrapper } from './ContentWrapper'
export { default as DropdownPrompt } from './DropdownPrompt'
