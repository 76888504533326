import { CREMATION_FUNERAL_PLAN_FEE } from 'config'

export const FUNERAL_PLANS_COMPETITOR_PRICES_LAST_UPDATED = 'August 2024'
export const DIRECT_CREMATION_COMPETITOR_PRICES_LAST_UPDATED = 'August 2024'
export const FUNERAL_PLANS_PROVIDER_FAREWILL = {
  name: 'Farewill',
  logotype: 'internal-logos/logo-farewill',
  price: CREMATION_FUNERAL_PLAN_FEE,
  highlighted: true,
}

export const FUNERAL_PLANS_PROVIDER_AFFORDABLE_FUNERALS = {
  name: 'Affordable Funerals',
  logotype: 'external-logos/logo-affordable-funerals-2023',
  price: 1895,
}

export const FUNERAL_PLANS_PROVIDER_GOLDEN_CHARTER = {
  name: 'Golden Charter',
  logotype: 'external-logos/golden-charter-logo',
  price: 1785,
}

export const FUNERAL_PLANS_PROVIDER_PURE_CREMATION = {
  name: 'Pure Cremation',
  logotype: 'external-logos/logo-pure',
  price: 1895,
}

export const FUNERAL_PLANS_PROVIDERS = [
  FUNERAL_PLANS_PROVIDER_FAREWILL,
  FUNERAL_PLANS_PROVIDER_AFFORDABLE_FUNERALS,
  FUNERAL_PLANS_PROVIDER_GOLDEN_CHARTER,
  FUNERAL_PLANS_PROVIDER_PURE_CREMATION,
]
