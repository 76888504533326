import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR, FONT } from '@farewill/ui/tokens'

const flash = keyframes`
  0% {
    opacity: 0.3;
  }
  80% {
    opacity: 1;
  }
`

const StyledIndicatorBlob = styled.span`
  background: ${COLOR.STATE.SUCCESS};
  content: '';
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  top: -1px;
  animation: ${flash} 0.8s alternate infinite ease-in;
  margin-right: 12px;
`

const StyledIndicatorText = styled.span`
  color: ${COLOR.STATE.SUCCESS};
  display: inline-block;
  font-weight: ${FONT.WEIGHT.BOLD};
`

const PhoneLineOpenIndicator = ({ className, text }) => (
  <Wrapper className={className}>
    <StyledIndicatorBlob />
    <StyledIndicatorText>{text}</StyledIndicatorText>
  </Wrapper>
)

PhoneLineOpenIndicator.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
}

PhoneLineOpenIndicator.defaultProps = {
  className: null,
  text: 'Line open',
}

export default PhoneLineOpenIndicator
