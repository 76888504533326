import {
  FUNERAL_PLANS_PROVIDER_AFFORDABLE_FUNERALS,
  FUNERAL_PLANS_PROVIDER_FAREWILL,
  FUNERAL_PLANS_PROVIDER_GOLDEN_CHARTER,
  FUNERAL_PLANS_PROVIDER_PURE_CREMATION,
} from 'lib/funeralPlans/constants'
import { SingleProviderProps } from './SingleProvider'

export const LABELS_KEYS = {
  plan: 'plan',
  cost: 'cost',
  directCremation: 'directCremation',
  coffin: 'coffin',
  urn: 'urn',
  handDeliveryOfAshes: 'handDeliveryOfAshes',
  urgentCollection: 'urgentCollection',
  removalOfMedicalDevices: 'removalOfMedicalDevices',
  source: 'source',
} as const

export const LABELS = {
  [LABELS_KEYS.plan]: 'Plan',
  [LABELS_KEYS.cost]: 'Cost',
  [LABELS_KEYS.directCremation]: 'Direct cremation',
  [LABELS_KEYS.coffin]: 'Coffin',
  [LABELS_KEYS.urn]: 'Urn',
  [LABELS_KEYS.handDeliveryOfAshes]: 'Hand delivery of ashes',
  [LABELS_KEYS.urgentCollection]: 'Urgent 24 hour collection',
  [LABELS_KEYS.removalOfMedicalDevices]: 'Removal of medical devices',
  [LABELS_KEYS.source]: 'Source',
}

const PROVIDER_FAREWILL: SingleProviderProps = {
  ...FUNERAL_PLANS_PROVIDER_FAREWILL,
  features: {
    [LABELS_KEYS.directCremation]: true,
    [LABELS_KEYS.coffin]: true,
    [LABELS_KEYS.urn]: true,
    [LABELS_KEYS.handDeliveryOfAshes]: true,
    [LABELS_KEYS.urgentCollection]: true,
    [LABELS_KEYS.removalOfMedicalDevices]: true,
  },
  [LABELS_KEYS.plan]: {
    name: 'Farewill Funerals Direct Cremation Plan',
    minimumWordsLineTwo: 3,
  },
  [LABELS_KEYS.source]: 'farewill.com/funerals',
}

const PROVIDER_AFFORDABLE_FUNERALS: SingleProviderProps = {
  ...FUNERAL_PLANS_PROVIDER_AFFORDABLE_FUNERALS,
  features: {
    [LABELS_KEYS.directCremation]: true,
    [LABELS_KEYS.coffin]: true,
    [LABELS_KEYS.urn]: true,
    [LABELS_KEYS.handDeliveryOfAshes]: true,
    [LABELS_KEYS.urgentCollection]: true,
    [LABELS_KEYS.removalOfMedicalDevices]: true,
  },
  [LABELS_KEYS.plan]: {
    name: 'Direct Cremation Premium Plan',
    minimumWordsLineTwo: 2,
  },
  [LABELS_KEYS.source]: 'low-cost-funeral.co.uk',
}

const PROVIDER_GOLDEN_CHARTER: SingleProviderProps = {
  ...FUNERAL_PLANS_PROVIDER_GOLDEN_CHARTER,
  features: {
    [LABELS_KEYS.directCremation]: true,
    [LABELS_KEYS.coffin]: true,
    [LABELS_KEYS.urn]: false,
    [LABELS_KEYS.handDeliveryOfAshes]: false,
    [LABELS_KEYS.urgentCollection]: false,
    [LABELS_KEYS.removalOfMedicalDevices]: false,
  },
  [LABELS_KEYS.plan]: {
    name: 'Golden Charter Basic Plan',
    minimumWordsLineTwo: 2,
  },
  [LABELS_KEYS.source]: 'goldencharter.co.uk',
}

const PROVIDER_PURE_CREMATION: SingleProviderProps = {
  ...FUNERAL_PLANS_PROVIDER_PURE_CREMATION,
  features: {
    [LABELS_KEYS.directCremation]: true,
    [LABELS_KEYS.coffin]: true,
    [LABELS_KEYS.urn]: true,
    [LABELS_KEYS.handDeliveryOfAshes]: true,
    [LABELS_KEYS.urgentCollection]: true,
    [LABELS_KEYS.removalOfMedicalDevices]: true,
  },
  [LABELS_KEYS.plan]: {
    name: 'Pure Cremation Funeral Plan',
    minimumWordsLineTwo: 2,
  },
  [LABELS_KEYS.source]: 'purecremation.co.uk',
}

export const PROVIDERS = [
  PROVIDER_FAREWILL,
  PROVIDER_AFFORDABLE_FUNERALS,
  PROVIDER_GOLDEN_CHARTER,
  PROVIDER_PURE_CREMATION,
]
